
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Stores list</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-sm-12 col-md-4">
          <div class="input-group">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <select v-model="defaultStatus" class="form-select flex-grow-1">
              <option value="" disabled selected>-- status --</option>
              <option v-for="(status, tIndex) in statusOptions"
                      :value="status.value" :key="tIndex" > {{status.label}} </option>
              <option value="all"> View all</option>
            </select>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <is-loading v-if="isLoading" />
      <div v-else>
        <div v-if="pageData.data.length" class="row">
          <div class="col-sm-6" v-for="(item, dIndex) of pageData.data" :key="dIndex">
            <store-card :store="item">
              <template v-slot:actions>
                <router-link :to="`/stores/${item.id}`" class="btn me-3 btn-primary btn-sm">
                View store</router-link>
              </template>
            </store-card>
          </div>
        </div>
        <div v-else class="fs-5 mt-4 text-muted text-center"> No store to show. </div>
      </div>
      <div class="my-5">
        <b-pagination v-model="currentPage" :limit="3" class="pagination-rounded"
          :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import StoreCard from "@/components/cards/StoreCard.vue"
import IsLoading from '@/components/IsLoading.vue'
import _ from 'lodash';

export default {
  components:{
    StoreCard,
    IsLoading,
  },
  data(){
    return {
      isLoading: true,
      searchQuery: "",
      pageOptions: [10, 25, 50, 100],
      defaultStatus: 'all',
      statusOptions:[
        { value: 'draft', label: "Draft" },
        { value: 'pending', label: "Pending" },
        { value: 'approved', label: "Approved" },
        {value: 'canceled', label: 'Canceled'},
      ]
    }
  },
  watch: {
    defaultStatus (val) {
      this.fetchItems()
    }
  },
  computed:{
    pageData(){
      return this.$store.state.store.pageData
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 25
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
  },
  methods: {
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null) {
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.defaultStatus != 'all'){ payload.status = this.defaultStatus }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("store/fetchStores", payload)
      .then((_) => this.isLoading = false )
    },
  },
  created(){
    this.fetchItems()
  },
}

</script>

<style lang="scss">

</style>
