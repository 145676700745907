<template>
<div class="card card-height-100">
    <div class="card-body">
        <div class="">
            <div class="friendly-id mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard">
                <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                </svg>
                {{store.friendly_id}}
            </div>
            <div class="row">
                <div class="col-12 col-md-6 mb-3">
                    <label class="form-label fw-bold">Name</label>
                    <div>{{store.title}}</div>
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label class="form-label fw-bold">Type</label>
                    <div>{{store.store_type.title}}</div>
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label class="form-label fw-bold">Phone Number</label>
                    <div>{{store.phone}}</div>
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label class="form-label fw-bold">Preferred Email</label>
                    <div>{{store.email}}</div>
                </div>
            </div>
            <hr> 
            <slot name="actions"></slot>
        </div>            
    </div>
</div>
</template>

<script>
export default {
    props:{
        store:{
            type:Object,
            required: true
        }
    },
    computed:{
     
    },    
}
</script>

<style>

</style>